const SCREEN_SIZE = {
  extraSmall: 374,
  small: 575,
  medium: 767,
  large: 1023,
  larger: 1199,
  extraLarge: 1439,
};

export { SCREEN_SIZE };
